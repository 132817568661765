import React, { useState } from "react";
import galerieCarouselImages from "./AllCarouselImage";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function CarouselSlide() {
  const carouselImages = galerieCarouselImages;
  const [index, setIndex] = useState(0);
  
  const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} >
      {carouselImages.map((_) =>(
        <Carousel.Item key={_.id}>
          <img
            className="d-block w-100 h4s-image"
            src={_.src}
            alt={_.id + " slide"}
          />
          <Carousel.Caption>
            
          </Carousel.Caption>
      </Carousel.Item>
      ))}
    </Carousel>
  )
  // const carouselImages = galerieCarouselImages;
  // const [index, setIndex] = useState(0);

  // const [slideInterval, setSlideInterval] = useState();

  // function slideShow() {
  //   setIndex((index) => (index === carouselImages.length - 1 ? 0 : index + 1));
  // }

  // useEffect(() => {
  //   const slide = setInterval(slideShow, 5000);
  //   setSlideInterval(slide);
  //   return () => {
  //     clearInterval(slideInterval);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // function increaseIndex() {
  //   clearInterval(slideInterval);
  //   let increasedIndex = index + 1;
  //   index === carouselImages.length - 1
  //     ? setIndex(0)
  //     : setIndex(increasedIndex);
  // }

  // function decreaseIndex() {
  //   clearInterval(slideInterval);
  //   let decreasedIndex = index - 1;
  //   index === 0
  //     ? setIndex(carouselImages.length - 1)
  //     : setIndex(decreasedIndex);
  // }

  // return (
  //   <div className="galerie-carousel">
  //     {carouselImages.map((image) => (
  //       <img
  //         key={image.id}
  //         className={index === image.id ? "show fade" : "hide"}
  //         src={image.src}
  //         alt={image.id + " slide"}
  //       />
  //     ))}
  //     <span className="prev" onClick={decreaseIndex}>
  //       &#10094;
  //     </span>
  //     <span className="next" onClick={increaseIndex}>
  //       &#10095;
  //     </span>
  //   </div>
  // );
}
