import React from "react";
import About from "./About";
import Welcome from "./Welcome";

export default function Home() {
  return (
    <div id="home">
      <Welcome />
      <About />
    </div>
  );
}
