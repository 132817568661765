import React from "react";
import "../../style/trainings.css";
import kepzesek from "./Traintypes";
import TrainCard from "./TrainCard";

export default function Trainings() {
  const allTrainingsType = kepzesek;

  return (
    <section id="trainings">
      <div className="section-titles">Képzéseink:</div>
      <div className="trainings-content">
        {allTrainingsType.map((trainType) => (
          <TrainCard type={trainType} key={trainType.id} />
        ))}
      </div>
    </section>
  );
}
