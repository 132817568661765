import React from "react";
import "../style/unavailable.css";

export default function UnderConstruction() {
  return (
    <div className="main-container">
      <h2 className="main">
        Még készülőben :) kérlek térj vissza később!{" "}
        <a className="main-link" href="/">
          FŐOLDAL
        </a>
      </h2>
    </div>
  );
}
