import React, { useState } from "react";
import "../style/header.css";
import { Icon } from "@iconify/react";
import kebabVertical from "@iconify/icons-codicon/kebab-vertical";
import Scrollchor from "react-scrollchor";
import { Link } from "react-router-dom";

export default function Header() {
  const [show, setShow] = useState(false);

  function toggleKebabMenu() {
    setShow(!show);
  }

  let path = window.location.pathname;

  return (
    <nav className={show ? "non-trasparent" : null}>
      <div className="logo-title">
        <img className="nav-logo" src="images/dcc_logo.png" alt="logo" />
        <div className="nav-title">
          <a href="/">Dance City Center</a>
        </div>
      </div>
      <button className="hamburger-menu" onClick={toggleKebabMenu}>
        <Icon icon={kebabVertical} color="black" />
      </button>

      <ul
        className={show ? "nav-list show" : "nav-list"}
        onClick={toggleKebabMenu}
      >
        <li>
          {path !== "/" ? (
            <Link to="/">Főoldal</Link>
          ) : (
            <Scrollchor to="#">Főoldal</Scrollchor>
          )}
        </li>
        <li>
          {path !== "/" ? (
            <a href="/#trainings">Képzéseink</a>
          ) : (
            <Scrollchor to="#trainings">Képzéseink</Scrollchor>
          )}
        </li>
        <li>
          {path !== "/" ? (
            <a href="/#event">Események</a>
          ) : (
            <Scrollchor to="#event">Események</Scrollchor>
          )}
        </li>
        <li>
          {path !== "/" ? (
            <a href="/#galerie">Galéria</a>
          ) : (
            <Scrollchor to="#galerie">Galéria</Scrollchor>
          )}
        </li>
        <li>
          {path !== "/" ? (
            <a href="/#availability">Elérhetőség</a>
          ) : (
            <Scrollchor to="#availability">Elérhetőség</Scrollchor>
          )}
        </li>
        <li>
          <a href="/hazirend">Házirend</a>
        </li>
        <li>
          <a href="/orarend">Órarend</a>
        </li>
        <li>
          <a href="/termekek">Termékeink</a>
        </li>
      </ul>
    </nav>
  );
}
