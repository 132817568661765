import React from "react";
import LazyLoad from "react-lazyload";
import "../../style/trainings.css";

export default function TrainCard(props) {
  // const [show, setShow] = useState("invisible");

  // function toggleDesc() {
  //   show === "visible" ? setShow("invisible") : setShow("visible");
  // }

  return (
    <LazyLoad once>
      <div
        // onClick={toggleDesc}
        className={
          props.type.id % 2 === 0
            ? "training-card right-card"
            : "training-card left-card"
        }
      >
        <div className="training-card-title">{props.type.title}</div>
        <div className="training-card-content">
          <div
            className={
              "training-card-content-background"
              // show === "visible"
              //   ? "training-card-content-background blur-image"
              //   : "training-card-content-background"
            }
            style={{
              background: `url(${props.type.image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition:
                props.type.title === "Zumba"
                  ? "25% 50%"
                  : "50% 50%" && props.type.title === "Hiphop"
                  ? "50% 10%"
                  : "50% 50%" &&
                    props.type.title ===
                      "Kanga training & Rectus training method"
                  ? "75% 50%"
                  : "50% 50%",
            }}
          ></div>
          <div className="card-content-desc-date">
            <span className="training-card-content-desc">
              {props.type.desc}
            </span>
            <span className="training-card-content-date">
              <strong>Időpontok</strong>: {props.type.date}
            </span>
            <span className="training-card-content-trainer">
              <strong>Edző</strong>: {props.type.trainer}
            </span>
            <span className="training-card-content-contact">
              <strong>Elérhetőség</strong>: {props.type.phone} |{" "}
              {props.type.email !== "" ? props.type.email + " |" : ""}{" "}
              {props.type.web !== "" ? (
                <a
                  // style={
                  //   show === "visible"
                  //     ? { pointerEvents: "all" }
                  //     : { pointerEvents: "default" }
                  // }
                  href={props.type.web}
                >
                  {props.type.webname != null ? props.type.webname : "honlap"} |
                </a>
              ) : (
                ""
              )}{" "}
              {props.type.facebook !== "" ? (
                <a
                  // style={
                  //   show === "visible"
                  //     ? { pointerEvents: "all" }
                  //     : { pointerEvents: "default" }
                  // }
                  href={props.type.facebook}
                >
                  facebook |
                </a>
              ) : (
                ""
              )}{" "}
              {props.type.instagram !== "" ? (
                <a
                  // style={
                  //   show === "visible"
                  //     ? { pointerEvents: "all" }
                  //     : { pointerEvents: "default" }
                  // }
                  href={props.type.instagram}
                >
                  instagram |
                </a>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        {/* <div className="training-card-title">{props.type.title}</div> */}
      </div>
    </LazyLoad>
  );
}
