import React from "react";
import LazyLoad from "react-lazyload";
import "../../style/welcome.css";

export default function Welcome() {
  return (
    <div>
      <section id="welcome2">
        <div className="welcome-title">
          <span>Üdvözöljük a </span>
          <br />
          <span>Dance City Centernél</span>
        </div>
      </section>
      <LazyLoad once>
        <section id="welcome"></section>
      </LazyLoad>
    </div>
  );
}
