import React from 'react'
import MapAndContact from './contact/MapAndContact'
import Event from './Event/Event'
import Galerie from './Galerie/Galerie'
import Home from './Home/Home'
import Trainings from './Trainings/Trainings'

export default function MainPart() {
    return (
        <div>
            <Home />
            <Trainings />
            <Event />
            <Galerie />
            <MapAndContact />
        </div>
    )
}
