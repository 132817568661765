import React from 'react'
import MapAndAvailability from './MapAndAvailability'
import "../../style/contact.css"
import ContactForm from './ContactForm'

export default function MapAndContact() {
    return (
        <div>
            <MapAndAvailability />
            <ContactForm />
        </div>
    )
}
