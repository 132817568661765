import React from "react";
import "../style/unavailable.css";

export default function _404() {
  return (
    <div className="main-container">
      {/* <h1 className="main">Hmmmm.... ninkérlek térj vissza a <a className="main-link" href="/">Főoldalra</a>!</h1> */}
      <h2 className="main">
        Szerintem eltévedtél, kérlek térj vissza a{" "}
        <a className="main-link" href="/">
          FŐOLDALRA
        </a>
        !
      </h2>
    </div>
  );
}
