import React from "react";
import { Icon } from "@iconify/react";
import mapMarker from "@iconify/icons-mdi/map-marker";
import youtubeFilled from "@iconify/icons-ant-design/youtube-filled";

// npm install --save-dev @iconify/react @iconify/icons-bx
// npm install --save-dev @iconify/react @iconify/icons-ant-design

import facebookFilled from "@iconify/icons-ant-design/facebook-filled";

import bxsPhone from "@iconify/icons-bx/bxs-phone";

export default function MapAndAvailability() {
  return (
    <section id="availability">
      <div className="section-titles">Merre találsz minket:</div>
      <div className="availability-content-container">
        <a href="https://www.google.com/maps/place/DANCE+CITY+CENTER+Musical-+%26+Tanciskola/@47.445328,19.1738198,18.5z/data=!4m5!3m4!1s0x0:0x14356e60ebfb8337!8m2!3d47.4454436!4d19.1739391">
          <img
            className="availability-map"
            src="/images/map.png"
            alt="Budapest, Üllői út 386, 1184"
          />
        </a>

        <div className="availability-container">
          <span className="availability-street">
            <a href="https://www.google.com/maps/place/DANCE+CITY+CENTER+Musical-+%26+Tanciskola/@47.445328,19.1738198,18.5z/data=!4m5!3m4!1s0x0:0x14356e60ebfb8337!8m2!3d47.4454436!4d19.1739391">
              <Icon className="icon-street" icon={mapMarker} color="red" />{" "}
              Budapest, Üllői út 386, 1184
            </a>
          </span>

          <span className="availability-phone">
            <Icon className="icon-phone" icon={bxsPhone} color="white" />{" "}
            +36704263943
          </span>

          <span className="availability-facebook">
            <a href="https://www.facebook.com/dancecitycenter/">
              <Icon
                className="icon-facebook"
                icon={facebookFilled}
                color="#4867AA"
              />{" "}
              <span>facebook</span>
            </a>
          </span>

          <span className="availability-instagram">
            <a href="https://www.instagram.com/dancecitycenter/">
              <img
                className="icon-instagram"
                src="/images/instagram.svg"
                alt="instagram"
              />{" "}
              <span>instagram</span>
            </a>
          </span>

          <span className="availability-youtube">
            <a href="https://www.youtube.com/channel/UCkmVERjzjaij9mmBBflGHCA">
              <Icon className="icon-youtube" icon={youtubeFilled} color="red" />{" "}
              <span>youtube</span>
            </a>
          </span>
        </div>
      </div>
    </section>
  );
}
