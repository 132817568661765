import React from "react";
import "../../style/about.css";

export default function About() {
  return (
    <section id="about">
      <div className="section-titles">Rólunk:</div>
      <div className="text-parent">
        <div className="about-text">
          <div className="about-text-paragraph">
            Dance City Center Musical-és Tánciskola
          </div>
          <div>
            A tánciskolát 2010-ben alapította Molnár Ferenc, a Madách Színház
            tánckarvezetője és koreográfusa, valamint Kun Vera, aki szintén a
            Madách Színház táncművésze volt. Már több mint 10 éve ebben a
            művészeti intézményben próbálják inspirálni, tanítani, átadni több
            évtizedes szakmai tudásukat az ifjúságnak. A tánciskolára jellemző a
            családias hangulat, a magas színvonalú és sokoldalú táncoktatás és a
            gyerekek lelkesedése. A táncolni vágyók többféle tanfolyam közül
            választhatnak, hiszen az ovis tánctól kezdve, jazztáncon át
            musicaltánc és hip-hop órákig minden megtalálható egy helyen.
            Ezenkívül elérhetőek felnőtt képzések is úgy mint a Beautyrobic,
            Kanga, Pilates illetve a közkedvelt Zumba órák és a személyi edzésre
            vágyóknak a Power Plate edzés. Növendékeinknek több lehetősége is
            van megcsillogtatni a tudásukat, hiszen a Karácsonyi és Évvégi
            Gálánkon kívül rengeteg kerületi és fővárosi rendezvényen is
            fellépünk, úgy mint a Tánc Világnapján, a Nemzetközi
            Táncfesztiválon, gimnáziumok szalagavatóin, a Művészetek Palotájában
            és egyéb helyszíneken is. Itt, a Dance City Centerben a művészet egy
            karnyújtásnyira van és egy kis kitartással bárki átélheti a színpad
            nyújtotta varázst. A Dance City Center tanárai mindig nagy
            szeretettel várnak minden érdeklődő gyermeket, szülőt, táncolni
            vágyót!
          </div>

          <div className="about-text-paragraph">Jazztánc</div>
          <div>
            A jazztánc a 20-as évek amerikájából indult. Azóta sok átalakuláson,
            fejlődésen ment keresztül. A harlemi afroamerikaiak tánca kiegészült
            a balett és a modern tánc elemeivel. Speciális eleme az izoláció,
            melyben különböző testrészek egymástól függetlenül mozognak.
            Iskolánkban a klasszikus balett metodikáján alapuló tréning
            hangulatos, dinamikus zenékre zajlik. A jazztánc széles palettájának
            köszönhetően változatos és különböző stílusú koreográfiák készülnek
            a gyerekek képességeit figyelembe véve. Remekül tudjuk hasznosítani
            a ritmikus gimnasztika és a showtánc elemeit. A jazztánc
            komplexitása nagyon jól fejleszti a gyerekek mozgáskoordinációját és
            koncentrációképességét, ami a tanulásban is hasznos lehet számukra.
          </div>
        </div>
      </div>
    </section>
  );
}
