import React from "react";
import "../../style/event.css";
import EventCard from "./EventCard";
import allEvents from "./AllEvent";

export default function Event() {
  const events = allEvents;
  return (
    <section id="event">
      <div className="section-titles">Közelgő Események:</div>
      <div className="event-container">
        {events.length < 1 ? (
          <h4
            style={{
              textAlign: "center",
              paddingTop: "10%",
              paddingBottom: "5%",
            }}
          >
            Sajnos jelenleg nincs közelgő esemény! <br /> Érdeklődni telefonon:
            +36704263943
          </h4>
        ) : (
          events.map((upcomingEvent) => (
            <EventCard key={upcomingEvent.id} currentEvent={upcomingEvent} />
          ))
        )}
        {/* {events.map((upcomingEvent) => (
          <EventCard key={upcomingEvent.id} currentEvent={upcomingEvent} />
        ))} */}
      </div>
    </section>
  );
}
