const kepzesek = [
  {
    id: 1,
    title: "Jazztánc",
    desc: "Mozgaskoordinációt fejlesztő, erősitő, lazitó tréning amely megalapozza a csoportos koreográfiához szükséges tudást (7-20 éves korig)",
    date: "Időpontról érdeklődni telefonon",
    trainer: "Kun Vera",
    phone: "+36704263943",
    email: "dcc.dancecitycenter@gmail.com",
    web: "",
    facebook: "https://www.facebook.com/dancecitycenter/",
    instagram: "https://www.instagram.com/dancecitycenter/",
    image: "/images/jazztanc.jpg",
  },
  {
    id: 2,
    title: "Ovis tánc",
    desc: "Táncos és játékos gimnasztika, és koreográfia (3-6 éves korig)",
    date: "Kedd-Csütörtök 17:30-18:30",
    trainer: "Molnár Melinda",
    phone: "Kun Vera: +36704263943",
    email: "",
    web: "",
    facebook: "",
    instagram: "",
    image: "/images/ovistanc.jpg",
  },
  {
    id: 3,
    title: "Hiphop",
    desc: "Különböző streetdance stílusok, főleg hiphop zenére (gyerekeknek, fiatal felnőtteknek)",
    date: "Péntek és Szombat délután",
    trainer: "Murányi László",
    phone: "+36705782913",
    email: "",
    web: "",
    facebook:
      "https://www.facebook.com/Jamland-hivatalos-oldal-402146269801603/",
    instagram: "https://www.instagram.com/jamlandhiphop/",
    image: "/images/hiphop.jpg",
  },
  {
    id: 4,
    title: "Zumba",
    desc: "Latin táncokon, életérzésen alapuló fitness program, a táncon keresztül dinamikus, izgalmas élménnyé teszi az edzést. (gyerekeknek, felnőtteknek)",
    date: "Kedd 18:45-19:45, Csütörtök 19:30-20:30",
    trainer: "Nagy-Borics Roland",
    phone: "+36309706015 ",
    email: "nagyboricsroland@gmail.com",
    web: "http://anitazumba.hu/",
    facebook: "https://hu-hu.facebook.com/anitazumba2/",
    instagram: "https://www.instagram.com/diosanitazumba/?hl=hu",
    image: "/images/zumba.jpg",
    webname: "anitazumba.hu",
  },  
];

export default kepzesek;
