import React from "react";
import LazyLoad from "react-lazyload";
import "../../style/galerie.css";
import CarouselSlide from "./CarouselSlide";

export default function Galerie() {
  return (
    <section id="galerie">
      <div className="section-titles">Galéria:</div>
      <div className="galerie-upper">
        <LazyLoad>
          <img src="/images/galeria1.jpg" alt="upper1" />
        </LazyLoad>
        <LazyLoad>
          <img src="/images/galeria2.jpg" alt="upper2" />
        </LazyLoad>
        <LazyLoad>
          <img src="/images/galeria3.jpg" alt="upper3" />
        </LazyLoad>
        <LazyLoad>
          <img src="/images/galeria4.jpg" alt="upper4" />
        </LazyLoad>
      </div>
      <div className="galerie-lower">
        <CarouselSlide />
      </div>
    </section>
  );
}
