import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainPart from "./component/MainPart";
import Header from "./layout/Header";
// import Products from "./component/Products/Products";
// import HouseRule from "./component/HouseRule";
import Footer from "./layout/Footer";
import UnderConstruction from "./component/UnderConstruction";
import _404 from "./component/404";

function App() {
  return (
    <div className="App">
      <Router>
        {["/", "/termekek", "/orarend", "/hazirend"].includes(
          window.location.pathname
        ) ? (
          <Header />
        ) : (
          ""
        )}
        <Switch>
          <Route exact path="/" component={MainPart} />
          <Route exact path="/termekek" component={UnderConstruction} />
          <Route exact path="/orarend" component={UnderConstruction} />
          <Route exact path="/hazirend" component={UnderConstruction} />
          <Route component={_404} />
        </Switch>
        {["/", "/termekek", "/orarend", "/hazirend"].includes(
          window.location.pathname
        ) ? (
          <Footer />
        ) : (
          ""
        )}
      </Router>
    </div>
  );
}

export default App;
