const allEvents = [
  // {
  //   id: 1,
  //   name: "Karácsonyi Táncgála",
  //   date: "Dec.12 18:00",
  //   desc: "Rózsa művelődési ház XVIII. kerület",
  //   price: "1000ft/db",
  // },
  // {
  //   id: 2,
  //   name: "Karácsonyi Táncgála",
  //   date: "Dec.18 17:00",
  //   desc: "Rózsa művelődési ház XVIII. kerület",
  //   price: "1000ft/db",
  // },
];

export default allEvents;
