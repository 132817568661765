import React from "react";
import "../style/footer.css";

export default function Footer() {
  return (
    <section id="footer">
      <h1 className="footer-title">
        <a href="/">Dance City Center</a>
      </h1>
      <div className="footer-content">
        <div className="footer-all-link">
          <h4>Összes link:</h4>
          <ul>
            <li>
              <a href="/#welcome2">Főoldal</a>
            </li>
            <li>
              <a href="/#trainings">Képzéseink</a>
            </li>
            <li>
              <a href="/#event">Események</a>
            </li>
            <li>
              <a href="/#galerie">Galéria</a>
            </li>
            <li>
              <a href="/#availability">Elérhetőség</a>
            </li>
            <li>
              <a href="/hazirend">Házirend</a>
            </li>
            <li>
              <a href="/orarend">Órarend</a>
            </li>
            <li>
              <a href="/termekek">Termékeink</a>
            </li>
          </ul>
        </div>
        <div className="footer-all-us">
          <h4>Elérhetőség:</h4>
          <ul>
            <li>Budapest 1184, Üllői út 386</li>
            <li>06 70 426 3943</li>
            <li>dcc.dancecitycenter@gmail.com</li>
          </ul>
        </div>

        <div className="footer-all-social">
          <h4>Kövess minket:</h4>
          <ul>
            <li>
              <a href="https://www.facebook.com/dancecitycenter/">facebook</a>
            </li>
            <li>
              <a href="https://www.instagram.com/dancecitycenter/">instagram</a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCkmVERjzjaij9mmBBflGHCA">
                youtube
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
