import React from "react";
import Scrollchor from "react-scrollchor";

export default function EventCard(props) {
  return (
    <div className="event-card">
      <h4 className="event-card-title">{props.currentEvent.name}</h4>
      <span className="event-card-date">{props.currentEvent.date}</span>
      <p className="event-card-desc">{props.currentEvent.desc}</p>

      <div className="event-card-price">
        <span className="price-title">Jegyek ára:</span>
        <span className="price-value">{props.currentEvent.price}</span>
      </div>
      <Scrollchor to="#contact" className="event-card-email-link">
        <button className="event-card-email">Írj emailt!</button>
      </Scrollchor>
    </div>
  );
}
