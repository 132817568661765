import React, { useState } from "react";
import emailjs from "emailjs-com";
require("dotenv").config();

export default function ContactForm() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_TYPE,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        e.target,
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setSuccess(true);

            setTimeout(function () {
              window.location.reload();
            }, 5000);
          }
        },

        (error) => {
          console.log(error);
          setError(true);
          setTimeout(function () {
            window.location.reload();
          }, 5000);
        }
      );
  }

  return (
    <section id="contact">
      <div className="section-titles">Írj nekünk:</div>
      <form className="contact-form" onSubmit={sendEmail} autoComplete="off">
        <input type="text" placeholder="Neved:" name="from_name" required />
        <input
          type="email"
          placeholder="Email címed:"
          name="from_email"
          required
          autoComplete="off"
        />
        <input type="text" placeholder="Tárgy:" name="subject" />
        <textarea placeholder="Üzenet:" name="html_message" required rows="6" />
        <br />
        <div className={success ? "show-status success" : "hide-status"}>
          Sikeresen elküldve!
        </div>
        <div className={error ? "show-status error" : "hide-status"}>
          Sajnos valami nem sikerült, kérlek próbáld később újra!
        </div>
        <input className="contact-send-button" type="submit" value="Send" />
      </form>
      <br />
    </section>
  );
}
