const galerieCarouselImages = [
  {
    id: 0,
    src: "/images/gc1.jpg",
  },
  {
    id: 1,
    src: "/images/gc2.jpg",
  },
  {
    id: 2,
    src: "/images/gc3.jpg",
  },
  {
    id: 3,
    src: "/images/gc4.jpg",
  },
  {
    id: 4,
    src: "/images/gc5.jpg",
  },
  {
    id: 5,
    src: "/images/gc6.jpg",
  },
  {
    id: 6,
    src: "/images/gc7.jpg",
  },
  {
    id: 7,
    src: "/images/gc8.jpg",
  },
  {
    id: 8,
    src: "/images/gc9.jpg",
  },
  {
    id: 9,
    src: "/images/gc10.jpg",
  },
  {
    id: 10,
    src: "/images/gc11.jpg",
  },
  {
    id: 11,
    src: "/images/gc12.jpg",
  },
  {
    id: 12,
    src: "/images/gc13.jpg",
  },
  {
    id: 13,
    src: "/images/gc14.jpg",
  },
  {
    id: 14,
    src: "/images/gc15.jpg",
  },
  {
    id: 15,
    src: "/images/gc16.jpg",
  },
  {
    id: 16,
    src: "/images/gc17.jpg",
  },
];

export default galerieCarouselImages;
